.client-container {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 30px;
    justify-content: space-around;
}

.client-card {
    position: relative;
    /* border: 1px solid #075120; */
    height: 350px;
    width: 350px;
    text-align: center;
    border-radius: 15px;
    background-color: lightblue;
    padding: 10px; 
}

.client-card img {
    width:80%;
    margin-top: 30px;
    /* background-color: #075120; */
    padding: 30px;

}

.client-card button {
    position: absolute;
    bottom: 20px;
    right: 30%;
    font-size: medium;
    color: white;
    background-color: black;
    width: 150px;
    height: 30px;
    border: none;

}

.client-card button:hover {
    background-color: crimson;

}

.title-div {
    text-align: center;
    margin-bottom: 50px;
    font-weight: 800;
}

.title-div h1 {
    font-size: 50px;
    background: -webkit-linear-gradient(20deg, #053574, #049c50, rgb(9, 27, 226));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.client-image {
    /* border: 1px solid black; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid slateblue;
    border-left: 1px solid slateblue;
    border-top-left-radius: 30px;

}

.client-detail {
    /* border: 1px solid blue; */
    background-color: slateblue;
    color: white;
    padding: 30px;
    border-bottom-right-radius: 30px;

}

@media screen and (max-width:800px) {
    .client-container {
        /* display: flex;
         */
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .client-image img {
        width: 100%;

    }

    .client-detail {
        /* border: 1px solid blue; */
        background-color: slateblue;
        color: white;
        padding: 20px;
        font-size: 5px;
        border-bottom-right-radius: 30px;

    }
}