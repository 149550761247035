@import url('https://fonts.googleapis.com/css2?family=Borel&family=Handjet:wght@100&display=swap');

.banner-container {
    display: grid;
    align-items: center;
    justify-content: space-evenly;
    grid-template-columns: repeat(2, 50%);
    background-color: #0f0c20;
}


.banner-text-div h1 {
    font-family: 'Borel', cursive;
    /* color: white; */
    font-size: 60px;
    text-shadow:
        0 0 7px #fff,
        0 0 10px #fff,
        /* 0 0 21px #fff, */
        0 0 42px #0fa,
        /* 0 0 82px #0fa,
        0 0 92px #0fa, */
        /* 0 0 102px #0fa, */
        0 0 151px #0fa;
}



.banner-image-div img {
    width: auto;
    filter: grayscale(60%);
}

.banner-container p {
    font-family: 'Borel', cursive;
    font-size: 20px;
    color: lightgray;
}








@media screen and (max-width:800px) {
    .banner-container {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        padding-top: 100px;
    }

    .banner-image-div {
        text-align: center;
        margin: 0;
    }

    .banner-image-div img {
        width: 80vw;
    }

    .banner-text-div {
        text-align: center;

    }

    .banner-container p {
        font-size: 20px;
        font-weight: bolder;
    }

    .banner-text-div h1 {
        /* color: white; */
        font-size: 50px;

    }
}