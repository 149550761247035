:root {
    --main-color: cornflowerblue
}

.skill-container {
    margin-bottom: 100px;
    margin-top: 50px;
}



.skill-element {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.skill-element h3 {
    background-color: var(--main-color);
    margin-left: 30px;
    padding: 8px;
    width: 95%;
    font-size: 15px;
}

.icon-div {
    display: flex;
    flex-wrap: wrap;

}

.skill-text h1 {
    text-align: center;
    font-size: 50px;
    margin: 60px;
    background: -webkit-linear-gradient(20deg, #053574, #049c50, rgb(9, 27, 226));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}

.icons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-content: center;
}

.skill-icons {
    width: 140px;
    height: 140px;
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 60px;
    padding: 15px;
    border: 1px solid var(--main-color);
    margin: 30px;
    color: var(--main-color);

}

.skill-icons p {
    font-size: 15px;
    font-weight: 700;
}

.skill-icons:hover {
    color: white;
    background-color: var(--main-color);

}





@media screen and (max-width:600px) {

    .skill-container {
        margin-top: 80px;
        margin-bottom: 100px;

    }

    .skill-text h1 {
        font-size: 20px;
    }

    .icons-container {
        display: grid;
        flex-wrap: wrap;
        justify-content: space-around;
        grid-template-columns: repeat(2, auto);
    }

    .skill-icons {
        width: 100px;
        height: 100px;
        display: grid;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 60px;
        padding: 15px;
        border: 1px solid var(--main-color);
        margin: 30px;
        color: var(--main-color);

    }

    .skill-icons p {
        font-size: 15px;
        font-weight: 700;
    }

    .skill-icons:hover {
        color: white;
        background-color: var(--main-color);

    }
}