.footer-container {
    margin-top: 150px;
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    background-color: lightblue;
    border-bottom: 1px solid cornflowerblue;

}




.footer-bottom {
    text-align: center;
    background-color: lightblue;
    margin-bottom: 0;
    height: 40px;
    padding: 10px;
    
}

.footer-text {
    text-align: center;

}

.footer-text h1 {
     
    font-weight: 600;

}

@media screen and (max-width:800px) {
    .footer-container {
        margin-top: 150px;
        text-align: center;
    }

    .footer-text {
        text-align: center;

    }

    .footer-text h1 {
        /* font-size: 70px;
        font-weight: 600; */

    }
}